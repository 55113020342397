<!--
 * @Author: 张冰华 690227436@qq.com
 * @Date: 2024-06-07 18:20:06
 * @LastEditors: 张冰华 690227436@qq.com
 * @LastEditTime: 2024-06-17 14:41:51
 * @FilePath: \cloud_campus_Front-end\school_end\src\components\scrollWrapper\SManagerEduMoralStatisticsScrollWrapper\OverviewOfGradeAssessment.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <!-- <div class="overview-of-grade-assessment"></div>
    <template> -->
    <div class="overview-of-grade-assessment">
        <div class="top">
            <!-- 站位div，用于flex布局中标题居中，如果不改布局勿删 -->
            <div class="title">
                <div class="icon"></div>
                年级考核概况
            </div>
            <div class="chart-legand">
                <div 
                    class="item"
                    v-for="item in seriesData"
                    :key="item.name"
                >
                    <div 
                        class="point"
                        :style="{backgroundColor:item.color}"
                    ></div>
                    <div class="name">{{item.name}}</div>
                </div>
            </div>
        </div>
        <div
            ref="chart"
            class="chart"
            style="width: 100%; height: 385px"
        ></div>
    </div>
</template>

<script>
import * as echarts from "echarts";

export default {
    name: "OverviewOfGradeAssessment",
    props: {
        data: {
            type: Array,
            default: () => []
        },
        listQuery: {
            type: Object,
            default: () => {}
        }
    },
    watch: {
        listQuery: {
            // immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.getGradeExamineInfo();
            }
        }
    },
    data() {
        return {
            chartInstance: null,
            seriesData:[
                {
                    name: "表扬",
                    type: "bar",
                    color: "#50E6F3",
                    data: [
                        
                    ],
                    barWidth: "10px",
                    itemStyle: {
                        borderRadius: [0,10,10,0],//圆角 
                    },
                },
                {
                    name: "批评",
                    type: "bar",
                    color: "#FB7A7A",
                    data: [],
                    barWidth: "10px",
                    itemStyle: {
                        borderRadius: [0,10,10,0],//圆角 
                    },
                },
            ],
            yAxisData: [
                
            ],
            gradeExamineInfo:[]
        };
    },
    computed: {
        dataZoomShow() {
            let limitLen = 8
            return this.yAxisData.length > limitLen
        }
    },
    mounted() {
        // this.initChart();
        this.getGradeExamineInfo();
    },
    methods: {
        getGradeExamineInfo() {
            this._fet(
                "/school/schoolMoralScreenData/gradeExamineInfo",
                this.listQuery,
            ).then( res => {
                if(res.data.code == "200"){
                    this.gradeExamineInfo = res.data.data;
                    this.$nextTick(() => {
                        this.initChart();
                    })
                }
            })
        },
        initChart() {
            this.hanldeData();
            const chartDom = this.$refs.chart;
            const myChart = echarts.init(chartDom);

            const option = {
                grid: {
                    left: "15%",
                    top: "6%",
                    width: "75%",
                    height: "95%",
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                },
                legend: {
                    show: false,
                    data: ["表扬", "批评"],
                },
                xAxis: {
                    type: "value",
                    position: "top",
                    name: "人",
                    
                    nameTextStyle: {
                        lineHeight:-28, //标题行高
                        verticalAlign:'top',//标题位置
                        // align:'top',
                        // padding: [0, 0, -30,0],
                    },
                },
                yAxis: {
                    type: "category",
                    data: this.yAxisData,
                    position: "left",
                    axisLine: {
                        lineStyle: {
                            color: "#A0AEC0",
                            type: "dashed",
                        },
                    },
                    axisTick: {
                        show: false
                    },
                },
                series: this.seriesData,
                dataZoom: [
                    {
                        // 设置滚动条的隐藏或显示
                        show: this.dataZoomShow,
                        // 设置类型
                        type: "slider",
                        // 设置背景颜色
                        backgroundColor: "rgb(248, 248, 248, 1)",
                        // 设置选中范围的填充颜色
                        fillerColor: "rgb(229, 231, 234)",
                        // 设置边框颜色
                        // borderColor: "rgb(229, 231, 234)",
                        // 是否显示detail，即拖拽时候显示详细数值信息
                        showDetail: false,
                        // 数据窗口范围的起始数值
                        startValue: 0,
                        // 数据窗口范围的结束数值（一页显示多少条数据）
                        endValue: 8,
                        // 控制哪个轴，如果是number表示控制一个轴，
                        // 如果是Array表示控制多个轴。此处控制第二根轴
                        yAxisIndex: [0, 1],
                        // empty：当前数据窗口外的数据，被设置为空。
                        // 即不会影响其他轴的数据范围
                        filterMode: "empty",
                        // 滚动条高度
                        width: 8,
                        // 滚动条显示位置
                        height: "100%",
                        // 距离右边
                        right: 3,
                        // 控制手柄的尺寸
                        handleSize: 0,
                        // 是否锁定选择区域（或叫做数据窗口）的大小
                        zoomLoxk: true,
                        // 组件离容器上侧的距离
                        // 如果top的值为'top', 'middle', 'bottom'，组件会根据相应的位置自动对齐
                        top: "middle",
                        brushSelect: false, // 关闭滚动条缩放 ***
                        // 是否开启滚动条内趋势
                        showDataShadow: false, 
                    },
                    {
                        // 没有下面这块的话，只能拖动滚动条，
                        // 鼠标滚轮在区域内不能控制外部滚动条
                        type: "inside",
                        // 控制哪个轴，如果是number表示控制一个轴，
                        // 如果是Array表示控制多个轴。此处控制第二根轴
                        yAxisIndex: [0, 1],
                        // 滚轮是否触发缩放
                        zoomOnMouseWheel: false,
                        // 鼠标移动能否触发平移
                        moveOnMouseMove: true,
                        // 鼠标滚轮能否触发平移
                        moveOnMouseWheel: true,
                    },
                ],
                
            };

            myChart.setOption(option);
        },
        hanldeData() {
            if(this.gradeExamineInfo.length <= 0){
                return;
            }
            this.gradeExamineInfo.reverse();
            this.seriesData.forEach(ele => {
                if(ele.name == "表扬" ) {
                    ele.data = this.gradeExamineInfo.map(item => item.addPerson)
                } else if (ele.name == "批评") {
                    ele.data = this.gradeExamineInfo.map(item => item.subPerson)
                }
            });

            this.yAxisData = this.gradeExamineInfo.map(item => item.gradeName);
            // 翻转数组
            // this.yAxisData.reverse();
        }
    },
};
</script>

<style lang="scss" scoped>
.overview-of-grade-assessment {
    width: 100%;
    // height: 400px;

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
            //
            // position: relative;
            // left: -70px;
            width: 200px;
            height: 107%;
            // border: 0px solid #3c7fff;
            // // border-right:none;
            // border-bottom: 1px solid #fff;

            background: #fff;
            font-weight: bold;
            font-size: 18px;
            color: #111111;
            box-sizing: border-box;
            display: flex;
            align-items: center;

            .icon {
                width: 10px;
                height: 20px;
                background: #3c7fff;
                border-radius: 0px 10px 10px 0px;
                margin-right: 12px;
            }
        }


        .chart-legand {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 140px;

            .item {
                display: flex;
                justify-content: space-between;
                align-items: center;

                margin-right: 10px;

                .point {
                    width: 15px;
                    height: 11px;
                    border-radius: 5px;
                    margin-right: 7px;
                }

                .name {
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                }

                
            }
        }
    }

    .chart {
        margin-top: 28px;
    }
}
</style>
