<template>
    <div class="grade-quantification">
        <layout-first :title="'年级量化'">
            <div
                class="chart"
                ref="chart"
                id="myChart"
            ></div>
        </layout-first>
    </div>
</template>

<script>
import * as echarts from "echarts"
import layoutFirst from "@/components/scrollWrapper/SManagerEduMoralStatisticsScrollWrapper/sub/layout-first.vue";
export default {
    name:"GradeQuantification",
    components: {
        layoutFirst,
    },
    props: {
        data: {
            type: Array,
            default: () => {}
        },
    },
    watch: {
        data: {
            deep: true,
            handler(newValue, oldValue) {
                this.initChart()
            }
        }
    },
    data() {
        return {
            chartData: {
                seriesName: "分",
                xAxisData: [],
                yAxisData: [],
            },
        };
    },
    computed: {
        dataZoomShow() {
            let limitLen = 9
            return this.chartData.xAxisData.length > limitLen
        }
    },
    mounted() {
        this.initChart();
    },
    methods: {
        initChart() {
            this.hanldeData();
            // 小屏幕宽度
            const limitWidth = 1540;
            // 浏览器宽度
            let windowWidth = document.body.clientWidth;
            let chartDom = this.$refs.chart;
            let myChart = echarts.init(chartDom);
            
            let option;
            const {seriesName, xAxisData, yAxisData} = this.chartData;
            option = {
                grid: {
                    left: windowWidth > limitWidth ? "10%" : "15%",
                    top: "15%",
                    width: "80%",
                    height: "70%",
                },
                tooltip: {
                    trigger: "item",
                    formatter: function (params) {
                        let str = "<div><p>" + params.name + "</p></div>";
                        str +=
                            params.marker +
                            params.seriesName +
                            "：" +
                            params.data.value;
                        return str;
                    },
                    textStyle: {
                        fontSize: 12,
                    },
                },
                xAxis: {
                    triggerEvent: true,
                    type: "category",
                    name: "",
                    nameLocation: "end",
                    nameTextStyle: {
                        color: "#595959",
                        fontSize: 12,
                        lineHeight:30, //标题行高
                        verticalAlign:'top',//标题位置
                        padding: [0, 0, 0, 20],
                    },
                    data: xAxisData,
                    boundaryGap: false,
                    axisLine: {
                        lineStyle: {
                            color: "#E1E7EA",
                            // type: "dashed",
                        },
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: "#595959",
                        fontSize: 12,
                        margin: 20,
                        interval: 0, // 设置为0显示所有标签
                        rotate: 20,
                        padding: [0, -30, 0, 0],
                        // offset: [30, 0], // 设置x轴标签偏移量
                        formatter: function(value) {
                               let res = value;
                               if(res.length > 5) {
                                    res = res.substring(0, 4) + "..";
                                }
                                return res;
                             }
                    },
                    tooltip: {
                        show: true,
                        trigger: 'item'
                    },
                    splitLine: {
                        show:false,
                        lineStyle: {
                            color: "#E1E7EA",
                            // type: "dashed",
                        },
                    },
                },
                yAxis: {
                    type: "value",
                    name: "分",
                    nameLocation: "end",
                    nameTextStyle: {
                        color: "#595959",
                        fontSize: 12,
                        padding: [0, 40, 0, 0],
                    },
                    splitLine: {
                        show:true,
                        lineStyle: {
                            color: "#E1E7EA",
                            // type: "dashed",
                        },
                    },
                    splitNumber: 5,
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        color: "#595959",
                        fontSize: 12,
                        margin: 15,
                    },
                },
                series: [
                    {
                        name: seriesName,
                        data: this.getYAxisData(yAxisData),
                        type: "bar",
                        barWidth: "10px",
                        // itemStyle: {
                        //     borderRadius: [10,10,0,0],//圆角
                        //     color: "#50E6F3", 
                        // },

                    },
                ],
                dataZoom: [
                    {   
                        // 设置滚动条的隐藏或显示
                        show: this.dataZoomShow,
                        // 设置类型
                        type: "slider",
                        // 设置背景颜色
                        backgroundColor: "rgb(248, 248, 248, 1)",
                        // 设置选中范围的填充颜色
                        fillerColor: "rgb(229, 231, 234)",
                        // 设置边框颜色
                        // borderColor: "rgb(229, 231, 234)",
                        // 是否显示detail，即拖拽时候显示详细数值信息
                        showDetail: false,
                        // 数据窗口范围的起始数值
                        startValue: 0,
                        // 数据窗口范围的结束数值（一页显示多少条数据）
                        endValue: 9,
                        // 控制哪个轴，如果是number表示控制一个轴，
                        // 如果是Array表示控制多个轴。此处控制第二根轴
                        xAxisIndex: [0, 1],
                        // empty：当前数据窗口外的数据，被设置为空。
                        // 即不会影响其他轴的数据范围
                        filterMode: "empty",
                        // 滚动条高度
                        width: "90%",
                        // 滚动条显示位置
                        height: 8,
                        // 距离右边
                        right: 20,
                        // dataZoom-slider组件离容器下侧的距离
                        bottom: 40,
                        // 控制手柄的尺寸
                        handleSize: 0,
                        // 是否锁定选择区域（或叫做数据窗口）的大小
                        zoomLoxk: true,
                        // 组件离容器上侧的距离
                        // 如果top的值为'top', 'middle', 'bottom'，组件会根据相应的位置自动对齐
                        top: "bottom",
                        brushSelect: false, // 关闭滚动条缩放 ***
                        // 是否开启滚动条内趋势
                        showDataShadow: false, 
                    },
                    {
                        // 没有下面这块的话，只能拖动滚动条，
                        // 鼠标滚轮在区域内不能控制外部滚动条
                        type: "inside",
                        // 控制哪个轴，如果是number表示控制一个轴，
                        // 如果是Array表示控制多个轴。此处控制第二根轴
                        xAxisIndex: [0, 1],
                        // 滚轮是否触发缩放
                        zoomOnMouseWheel: false,
                        // 鼠标移动能否触发平移
                        moveOnMouseMove: true,
                        // 鼠标滚轮能否触发平移
                        moveOnMouseWheel: true,
                    },
                ],
            };
            option && myChart.setOption(option,true);

            extension(myChart)
            function extension(mychart) {
                // 判断是否创建过div框,如果创建过就不再创建了
                var id = document.getElementById("extension");
                if (!id) {
                    var div = document.createElement('div');
                    div.id = "extension";
                    div.style.display = "none";
                    document.body.appendChild(div);
                }

                mychart.on('mouseover', function (params) {
                    if (params.componentType === "xAxis") {
                        var extension = document.getElementById("extension");
                        extension.style.position = "absolute";
                        // extension.style.color = "black";
                        // extension.style.border = "solid 2px white";
                        extension.style.fontFamily = "Arial";
                        // extension.style.fontSize = "20px";
                        // extension.style.padding = "5px";
                        extension.style.display = "inline";
                        extension.style.zIndex = 10;
                        extension.textContent = params.value;

                        document.body.addEventListener('mousemove', function (event) {
                            var xx = event.pageX - 30;
                            var yy = event.pageY + 20;
                            extension.style.top = yy + 'px';
                            extension.style.left = xx + 'px';
                        });
                    }
                });

                mychart.on('mouseout', function (params) {
                    if (params.componentType === "xAxis") {
                        var extension = document.getElementById("extension");
                        extension.style.display = 'none';
                    }
                })
            }

            // window.addEventListener("resize", function () {
            //     myChart.resize();
            // });
        },
        getYAxisData(data){
            if(data.length <= 0){
                return []
            }

            let yAxisData = [];
            for (let i = 0; i < data.length; i++) {
                yAxisData.push({
                    value:data[i],
                    itemStyle: {
                        borderRadius: data[i] > 0 ? [10,10,0,0] : [0,0,10,10],//圆角
                        color: "#50E6F3", 
                    }
                })
                
            }

            return yAxisData;
        },
        hanldeData() {
            if(this.data.length <= 0){
                return;
            }
            this.chartData.xAxisData = this.data.map(item => item.gradeName);
            this.chartData.yAxisData = this.data.map(item => item.score)
            
        }
    },
    mounted() {
        this.initChart();
    },
    watch: {
        data: {
            handler(newVal, oldVal) {
                this.initChart();
            },
            deep: true,
        }
    },
    
}
</script>

<style lang="scss" scoped>
    .grade-quantification{
        width: 100%;
        height: 338px;
        position: relative;
        overflow: hidden;
        background: #FFFFFF;
        box-shadow: 0px 6px 6px 0px rgba(179,179,179,0.24);
        border-radius: 10px;

        .chart {
            width: 100%;
            height: 300px;
        }
        
    }
</style>

<style lang="scss">
#extension {
        // display: flex;
        // flex-wrap: wrap;
        // position: absolute;
        padding: 5px;
        font-size: 12px;
        // border: 1px solid #ccc;
        border-radius: 8px;
        background-color: rgba(21, 20, 20, 0.8); /* 黑色背景，50% 透明度 */
        color:#fff;
        // z-index: 10;
    }
</style>