<template>
    <div class="Supervision">
        <layout-secound :title="'德育督导'">
            <div class="crb-content">
                <div class="table">
                    <el-table
                        :data="data"
                        style="width: 100%"
                        height="355px"
                        :header-cell-style="{
                            'text-align': 'center',
                            background: '#F5F5F5',
                            color: '#555',
                        }"
                        :cell-style="{'text-align': 'center'}"
                        ref="scroll_Table"
                        stripe
                        @mouseenter.native="autoScroll(true)"
                        @mouseleave.native="autoScroll(false)"
                    >
                        <el-table-column
                            prop="teacherName"
                            label="班主任"
                            
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                            prop="classNames"
                            label="负责班级"
                            show-overflow-tooltip
                        >
                            <template slot-scope="scope">
                                {{scope.row.classNames || "-"}}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="unHandleScore"
                            label="未处理分数"
                            
                            show-overflow-tooltip
                        >
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </layout-secound>
    </div>
</template>

<script>
import layoutSecound from "@/components/scrollWrapper/SManagerEduMoralStatisticsScrollWrapper/sub/layout-secound.vue";
export default {
    name: "Supervision",
    props: {
        data: {
            type: Array,
            default: () => []
        },
    },
    components: {
        layoutSecound,
    },
    data() {
        return {
            
        };
    },
    mounted() {
        setTimeout(() => {
            this.autoScroll();
        }, 1000);
    },
    beforeDestroy() {
        this.autoScroll(true);
    },
    methods: {
        // 设置自动滚动
        autoScroll(stop) {
            const table = this.$refs.scroll_Table;
            // 拿到表格中承载数据的div元素
            const divData = table.$refs.bodyWrapper;
            // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每100毫秒移动1像素)
            if (stop) {
                //再通过事件监听，监听到 组件销毁 后，再执行关闭计时器。
                window.clearInterval(this.scrolltimer);
            } else {
                this.scrolltimer = window.setInterval(() => {
                    // 元素自增距离顶部1像素
                    divData.scrollTop += 1;
                    // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
                    if (
                        divData.clientHeight + divData.scrollTop ==
                        divData.scrollHeight
                    ) {
                        // 重置table距离顶部距离
                        divData.scrollTop = 0;
                        // 重置table距离顶部距离。值=(滚动到底部时，距离顶部的大小) - 整个高度/2
                        // divData.scrollTop = divData.scrollTop - divData.scrollHeight / 2
                    }
                }, 150); // 滚动速度
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.Supervision {
    width: 100%;
    height: 432px;
    overflow: hidden;
    background: #FFFFFF;
    box-shadow: 0px 6px 6px 0px rgba(179,179,179,0.24);
    border-radius: 10px;

    .text {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding-left: 26px;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        height: 33px;
    }

    .type-select {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        height: 33px;
        ::v-deep .el-select {
            width: 91px;
            height: 28px;

            .el-input__inner {
                background: #f1f6ff;
            }
        }
    }
    .crb-content {
        flex: 1;
        position: relative;

        .table {
            width: 99%;
            box-sizing: border-box;
            padding: 10px;
            position: absolute;
        }
    }

    ::v-deep .el-table.el-table--small .el-table__body tr {
        height: 36px !important;
    }
    ::v-deep .el-table--small .el-table__cell {
        padding: 5px 0;
    }
}
</style>
